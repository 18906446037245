<template src="./ReportTest.html"></template>

<script>
import print_div from './printReport';
export default {
  name: 'ReportTest',
  data: () => ({
    tab: null,
    tabs: [
      { key: 'mounthGroup', name: 'Оплата НА месяц' },
      { key: 'periodGroup', name: 'Оплата за период' },
    ],
    printData: {
      reportloaded: true,
      print: print_div,
    },

    params: {},

    team: {
      teamList: [],
      teamSelect: 0,
    },
    child: {
      children: [],
      childSelect: 0,
      childReady: false,
    },
    tables: {
      mounthGroup: {
        groupBy: 'group',
        groupByShow: true,
        ready: false,
        datapickerRange: false,
        datesRange: new Date().toISOString().substr(0, 7),
        headers: [
          { text: 'Коллектив', value: 'group' },
          { text: 'Группа', value: 'subGroup' },
          { text: 'Родитель', value: 'parent' },
          { text: 'Ученик', value: 'children' },
          { text: 'Сумма', value: 'price' },
          { text: 'Дата платежа', value: 'date' },
        ],
        printVers: {
          show: {
            text: ['Посмотреть данные на печать', 'Скрыть данные на печать'],
            flag: false,
          },
          headers: [
            { text: 'Коллектив', value: 'group', sortable: false },
            { text: 'Группа', value: 'subGroup', sortable: false },
            { text: 'Дата платежа', value: 'date', sortable: false },
            { text: 'Родитель', value: 'parent', sortable: false },
            { text: 'Ученик', value: 'children', sortable: false },
            { text: 'Сумма', value: 'price', sortable: false },
          ],
          data: [],
          date: '',
          groupsHeader: [
            { text: 'Коллектив', value: 'group', sortable: false },
            { text: '', value: '', sortable: false },
            { text: 'Группа', value: 'subGroup', sortable: false },
            {
              text: 'Количество',
              value: 'count',
              sortable: false,
              align: 'center',
            },
            { text: 'Сумма', value: 'price', sortable: false, align: 'center' },
          ],
          collective: [],
          groups: [],
          groupsCount: [],
        },
        dataModifier: [],
        dataModCount: {
          payments: 0,
          price: 0,
        },
        team: {
          teamSelect: 0,
          fetchID: 0,
        },
        child: {
          children: [],
          childSelect: 0,
          childReady: false,
        },
      },
      periodGroup: {
        groupBy: 'group',
        groupByShow: true,
        ready: false,
        datapickerRange: false,
        datesRange: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10),
        ],
        headers: [
          { text: 'Коллектив', value: 'group' },
          { text: 'Группа', value: 'subGroup' },
          { text: 'Дата платежа', value: 'date' },
          { text: 'Оплата за', value: 'orderBy' },
          { text: 'Родитель', value: 'parent' },
          { text: 'Ученик', value: 'children' },
          { text: 'Сумма', value: 'price' },
        ],
        printVers: {
          show: {
            text: ['Посмотреть данные на печать', 'Скрыть данные на печать'],
            flag: false,
          },
          headers: [
            { text: 'Коллектив', value: 'group', sortable: false },
            { text: 'Группа', value: 'subGroup', sortable: false },
            { text: 'Дата платежа', value: 'date', sortable: false },
            { text: 'Оплата за', value: 'orderBy', sortable: false },
            { text: 'Родитель', value: 'parent', sortable: false },
            { text: 'Ученик', value: 'children', sortable: false },
            { text: 'Сумма', value: 'price', sortable: false },
          ],
          data: [],
          date: [],
          groupsHeader: [
            { text: 'Коллектив', value: 'group', sortable: false },
            { text: '', value: '', sortable: false },
            { text: 'Группа', value: 'subGroup', sortable: false },
            {
              text: 'Количество',
              value: 'count',
              sortable: false,
              align: 'center',
            },
            { text: 'Сумма', value: 'price', sortable: false, align: 'center' },
          ],
          collective: [],
          groups: [],
          groupsCount: [],
        },
        dataModifier: [],
        dataModCount: {
          children: 0,
          price: 0,
        },
        team: {
          teamSelect: 0,
          fetchID: 0,
        },
        child: {
          children: [],
          childSelect: 0,
          childReady: false,
        },
      },
    },
  }),
  created() {
    console.log('report');
    this.groupLoad();
    this.loadReportByPeriod('periodGroup');
    this.loadReportToMonth('mounthGroup');
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    computedDateFormattedDatefnsMounthGroup() {
      return this.tables.mounthGroup.datesRange
        ? new Date(this.tables.mounthGroup.datesRange).toLocaleString('ru', {
            year: 'numeric',
            month: 'long',
            timezone: 'UTC',
          })
        : '';
    },
    dateRangeNewText() {
      // return this.DB_dates.join(' ~ ')
      return this.dateCompare();
    },
  },
  methods: {
    dateCompare() {
      let { datesRange, datapickerRange } = this.tables.periodGroup;

      let first = new Date(datesRange[0]);
      let second = new Date(datesRange[1]);
      let sortableData = [];
      if (first > second) {
        sortableData = [datesRange[1], datesRange[0]];
      } else {
        sortableData = [datesRange[0], datesRange[1]];
      }
      if (datesRange.length === 2 && datapickerRange === false) {
        datesRange.splice(0, 2, sortableData[0], sortableData[1]);
      } else if (datesRange.length === 1 && datapickerRange === false) {
        datesRange.splice(0, 2, sortableData[0], sortableData[0]);
      }

      if (datapickerRange === false) {
        this.loadReportByPeriod('periodGroup');
      }
      console.log(datapickerRange);
      return datesRange.join(' ~ ');
    },
    // Получение всех групп
    groupLoad() {
      console.log('start');
      let pr2 = this.$store.state.api_dk.LoadTeam();
      pr2.then((data) => {
        this.team.teamList = data;

        let zeroItem = { id: 0, name: 'Все группы', type: 1 };
        this.team.teamList.unshift(zeroItem);
      }).then(() => {
        this.test = this.team.teamList;
        console.log('end');
      });

    },
    subGroup(table, type) {
      this.tables.mounthGroup.printVers.show.flag = false;
      this.tables.periodGroup.printVers.show.flag = false;

      let parentCase,
        originalCase = this.team;
      let childCase = this.child;
      switch (table) {
        case 'mounthGroup':
          parentCase = this.tables.mounthGroup.team;
          childCase = this.tables.mounthGroup.child;
          break;
        case 'periodGroup':
          parentCase = this.tables.periodGroup.team;
          childCase = this.tables.periodGroup.child;
          break;
      }

      let item = originalCase.teamList.find(
        (el) => el.id === parentCase.teamSelect
      );
      let typeGroup, child;

      switch (type) {
        case 'child':
          child = childCase.children.find(
            (el) => el.id === childCase.childSelect
          );
          typeGroup = child.type;
          break;
        default:
          typeGroup = item.type;
      }

      switch (typeGroup) {
        case 1:
          childCase.children = [];
          childCase.childReady = false;
          childCase.childSelect = 0;
          parentCase.fetchID = item.id;
          console.log('Должны запросить: ' + item.id);
          switch (item.id) {
            case 0:
              this.actionGroup(table, true);
              break;
            default:
              this.actionGroup(table);
          }
          break;
        case 2:
          childCase.children = [];
          childCase.children = item.child;
          childCase.childReady = true;
          parentCase.fetchID = item.id;
          console.log('Должны запросить: ' + item.id);
          break;
        case 3:
          childCase.childSelect = child.id;
          parentCase.fetchID = child.id;
          console.log('Должны запросить: ' + item.id);
          break;
        default:
          console.log('Чет не нашлось');
          childCase.childReady = false;
      }
      switch (table) {
        case 'mounthGroup':
          this.loadReportToMonth(table);
          break;
        case 'periodGroup':
          this.loadReportByPeriod(table);
          break;
      }
    },
    tape2ListGroup(data) {
      // this.test2
      let newData = data;
      newData.forEach((item) => {
        this.team.teamList.forEach((group) => {
          switch (group.type) {
            case 1:
              if (group.id === item.team_id) {
                item.group = group.name;
                item.type = group.type;
              }
              break;
            case 2:
              group.child.forEach((subGroup) => {
                if (subGroup.id === item.team_id) {
                  item.group = group.name;
                  item.type = group.type;
                  item.subGroup = subGroup.full_name;
                }
              });
              break;
          }
        });
      });

      return newData;
    },
    countAllinTable(type) {
      let { dataModifier, dataModCount } = type;

      dataModCount.payments = dataModifier.length;
      dataModCount.price = 0;
      dataModifier.forEach((item) => {
        dataModCount.price += item.fullInfo.price;
      });
    },
    countGroup(data, type) {
      let { groups, collective } = type;
      groups.splice(0, groups.length);
      collective.splice(0, collective.length);

      data.forEach((item) => {
        let finded = groups.find((el) => el.team_id === item.team_id);
        let newItem = {
          team_id: item.team_id,
          group: item.group,
          subGroup: item.subGroup,
          price: item.fullInfo.price,
          count: 1,
        };
        switch (finded) {
          case undefined:
            groups.push(newItem);
            break;
          default:
            finded.count += 1;
            finded.price += item.fullInfo.price;
        }
      });
      groups.forEach((item) => {
        let findedC = collective.find((el) => el.group === item.group);
        let newCollective;
        switch (findedC) {
          case undefined:
            newCollective = {
              group: item.group,
              price: item.price,
              count: item.count,
            };
            collective.push(newCollective);
            break;
          default:
            findedC.count += item.count;
            findedC.price += item.price;
        }
      });
      console.log('groups');
      console.log(groups);
    },
    actionGroup(type, action) {
      let parent, indexToDelete;
      switch (type) {
        case 'periodGroup':
          parent = this.tables.periodGroup;
          break;
        case 'mounthGroup':
          parent = this.tables.mounthGroup;
          break;
      }
      switch (action) {
        case true:
          console.log('Добавили вывод коллектива/группы !!!');
          indexToDelete = parent.headers.findIndex(
            (el) => el.value === 'subGroup'
          );
          if (indexToDelete === -1) {
            parent.headers.splice(0, 0, { text: 'Группа', value: 'subGroup' });
          }
          indexToDelete = parent.headers.findIndex(
            (el) => el.value === 'group'
          );
          if (indexToDelete === -1) {
            parent.headers.splice(0, 0, { text: 'Коллектив', value: 'group' });
          }
          parent.groupBy = 'group';
          break;
        case false:
          console.log('Только убираем группировку!!');
          parent.groupBy = [];
          break;
        default:
          console.log('Убираем группировку и коллекетив/группу!!!!!!');
          indexToDelete = parent.headers.findIndex(
            (el) => el.value === 'group'
          );
          if (indexToDelete !== -1) {
            parent.headers.splice(indexToDelete, 1);
          }
          indexToDelete = parent.headers.findIndex(
            (el) => el.value === 'subGroup'
          );
          if (indexToDelete !== -1) {
            parent.headers.splice(indexToDelete, 1);
          }
          parent.groupBy = [];
      }
    },
    dataReSort(arr) {
      let newArr = [];

      arr.forEach((item) => {
        let date = item.payment_date.slice(0, 10);
        let orderToDate = '';
        switch (item.team_id) {
          case 26:
            orderToDate = new Date(
              item.to_year + '-' + item.to_month + '-' + item.to_day
            ).toLocaleString('ru', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              timezone: 'UTC',
            });
            break;
          default:
            orderToDate = new Date(
              item.to_year + '-' + item.to_month
            ).toLocaleString('ru', {
              year: 'numeric',
              month: 'long',
              timezone: 'UTC',
            });
        }
        let info = {
          status: item.status,
          description: item.description,
          email: item.email,
          rrn: item.rrn,
          payment_date: item.payment_date,
          discounted: item.discounted, //TODO была ли скидка
          full_price: item.full_price,
          payment_id: item.payment_id,
          query_id: item.query_id,
        };
        let fullInfo = item;
        let newItem = {
          parent: item.parent,
          children: item.children,
          price: item.price + ' руб.',
          team_id: item.team_id,
          date: date,
          info: info,
          fullInfo: fullInfo,
          show: true,
          orderBy: orderToDate,
        };
        newArr.push(newItem);
      });
      return newArr;
    },
    printTimeText(type) {
      switch (type) {
        case 'mounthGroup':
          this.tables.mounthGroup.printVers.date = new Date(
            this.tables.mounthGroup.datesRange.slice(0, 4) +
              '-' +
              this.tables.mounthGroup.datesRange.slice(5, 7)
          ).toLocaleString('ru', {
            month: 'long',
            year: 'numeric',
            timezone: 'UTC',
          });
          break;
        case 'periodGroup':
          this.tables.periodGroup.printVers.date[0] = new Date(
            this.tables.periodGroup.datesRange[0].slice(0, 4) +
              '-' +
              this.tables.periodGroup.datesRange[0].slice(5, 7) +
              '-' +
              this.tables.periodGroup.datesRange[0].slice(8, 10)
          ).toLocaleString('ru', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            timezone: 'UTC',
          });
          this.tables.periodGroup.printVers.date[1] = new Date(
            this.tables.periodGroup.datesRange[1].slice(0, 4) +
              '-' +
              this.tables.periodGroup.datesRange[1].slice(5, 7) +
              '-' +
              this.tables.periodGroup.datesRange[1].slice(8, 10)
          ).toLocaleString('ru', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            timezone: 'UTC',
          });
          break;
      }
    },
    datasReLoader(dataType, flag, data) {
      switch (dataType) {
        case 'mounthGroup':
          if (flag) {
            this.tables.mounthGroup.dataModifier = this.dataReSort(data);
            this.tables.mounthGroup.dataModifier = this.tape2ListGroup(
              this.tables.mounthGroup.dataModifier
            );
            this.countGroup(
              this.tables.mounthGroup.dataModifier,
              this.tables.mounthGroup.printVers
            );
            this.countAllinTable(this.tables.mounthGroup);
            this.printTimeText(dataType);
          }
          this.tables.mounthGroup.ready = flag;
          break;
        case 'periodGroup':
          if (flag) {
            this.tables.periodGroup.dataModifier = this.dataReSort(data);
            this.tables.periodGroup.dataModifier = this.tape2ListGroup(
              this.tables.periodGroup.dataModifier
            );
            this.countGroup(
              this.tables.periodGroup.dataModifier,
              this.tables.periodGroup.printVers
            );
            this.countAllinTable(this.tables.periodGroup);
            this.printTimeText(dataType);
          }
          this.tables.periodGroup.ready = flag;
          break;
      }
    },
    // платежи сделанные в указанный период
    loadReportByPeriod(dataType) {
      this.datasReLoader(dataType, false);
      let { team, datesRange } = this.tables.periodGroup;

      let post_data = {
        data: {
          base: {
            start: datesRange[0],
            end: datesRange[1],
            status: 50, // 10 - создан, 30 - отказ, 50 - оплачен
            team: team.fetchID,
          },
        },
      };

      let pr2 = this.$store.state.api_dk.FindReportByPeriod(post_data);
      pr2.then((data) => {
        if (data.b) {
          this.datasReLoader(dataType, true, data.d);
          console.log(data);
        } else {
          console.log('Данные не получены');
        }
      })
    },

    // Оплатчено на указанные месяц
    loadReportToMonth(dataType) {
      this.datasReLoader(dataType, false);
      let { team, datesRange } = this.tables.mounthGroup;

      let post_data = {
        data: {
          base: {
            year: datesRange.slice(0, 4),
            month: datesRange.slice(5, 7),
            team: team.fetchID,
          },
        },
      };

      let pr2 = this.$store.state.api_dk.FindReportByMonth(post_data);
      pr2.then((data) => {
        if (data.b) {
          this.datasReLoader(dataType, true, data.d);
          console.log(data);
        } else {
          console.log('Данные не получены');
        }
      })

    },
    flagInterpritator(txt, val) {
      let text;
      switch (txt) {
        case 'discounted':
          text = val ? 'Да' : 'Нет';
          break;
        case 'full_price':
          text = val ? val + ' руб.' : '';
          break;
        case 'status':
          switch (val) {
            case 10:
              text = 'Создано';
              break;
            case 30:
              text = 'Отказано';
              break;
            case 50:
              text = 'Оплачено';
              break;
          }
          break;
        default:
          text = val;
      }
      return text;
    },
    dataInfoName(txt) {
      let text;
      switch (txt) {
        case 'status':
          text = 'Статус';
          break;
        case 'description':
          text = 'Описание';
          break;
        case 'discounted':
          text = 'Скидка';
          break;
        case 'full_price':
          text = 'Полная цена';
          break;
        case 'payment_id':
          text = 'ID платежа';
          break;
        default:
          text = txt;
      }
      return text;
    },
    statusItem(val) {
      let text = '';
      switch (val) {
        case 10:
          text = 'Создан';
          break;
        case 30:
          text = 'Отказ';
          break;
        case 50:
          text = 'Оплачен';
          break;
      }
      return text;
    },
    //  print
    do_print(target) {
      //console.log(this.$refs.ttt);
      this.printData.print.print(target);
    },
    rowClass() {
      let rowClass = 'table-row-border';
      return rowClass;
    },
  },
};
</script>

<style>
.bordered-table .v-data-table__wrapper table {
  border-collapse: collapse !important;
}
.bordered-table .v-row-group__header,
.bordered-table .table-row-border-last {
  border: 2px solid #000;
}
.bordered-table thead.v-data-table-header tr > th,
.bordered-table .v-data-table__wrapper .table-row-border td {
  border: 2px solid #000;
  width: 20%;
}
.table-row-border td:last-child {
  white-space: nowrap;
  text-align: center;
}
</style>

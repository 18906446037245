const print_div = {
  print(elem) {
    console.log(elem);

    let mywindow = window.open('', 'PRINT', 'height=900,width=800');

    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );
    mywindow.document.write('</head><body>');
    mywindow.document.write(
      '<style>table{border-collapse: collapse;}table th, table td { padding: 0 16px;height: 48px;border: 2px solid #000;width: 20%;}.table-row-border td:first-child{padding-left:24px;}.v-row-group__header td.text-center,.table-row-border td.text-center,.table-row-border td:last-child{white-space: nowrap;text-align:center;}</style>'
    );
    //mywindow.document.write('<h1>' + document.title  + '</h1>');
    //mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write(elem.innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    //mywindow.close();

    return true;
  },
};

export default print_div;
